import { Stack, Typography } from "@mui/material";
import Loop from "src/components/loop";

const UsernameList = ({ list }) => {
  return (
    <Stack spacing={0.5}>
      <Loop
        list={list}
        render={(uname) => {
          return <Typography variant="body2">{uname}</Typography>;
        }}
      />
    </Stack>
  );
};
export default UsernameList;
