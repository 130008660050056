import {
    Card,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import Iconify from "src/components/Iconify";
import Loop from "src/components/loop";
import Ternary from "src/components/ternary";
import UsernameList from "./components/username-list";
import useAssign from "./hooks/use-assign";
import useGetSubAdminList from "./hooks/use-get-subadmin-list";
import useSelectSubAdmin from "./hooks/use-select-subadmin";

const AssignTo = ({ assignedSubadminIds }) => {
    const [openSubAdminList, setOpenSubAdminList] = useState(null);
    const { subAdminList, loadingSubAdmin } = useGetSubAdminList();
    const { selectSubAdmin, selectedSubAdmins } =
        useSelectSubAdmin(assignedSubadminIds);

    const selectedUserNames = useMemo(() => {
        if (selectedSubAdmins.length > 0 && subAdminList.length > 0) {
            return subAdminList
                .filter(
                    ({ id: mainId }) =>
                        selectedSubAdmins.findIndex((id) => id === mainId) > -1
                )
                .map(({ username }) => username);
        }
        return [];
    }, [selectedSubAdmins, subAdminList]);

    const handleOpenSubAdminList = async (e) => {
        setOpenSubAdminList(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenSubAdminList(null);
    };

    const onSubmit = useAssign(selectedSubAdmins, () => {
        handleCloseMenu();
    });

    const hideMenu = useMemo(() => {
        if (loadingSubAdmin) {
            return true;
        }
        if (subAdminList.length === 0) {
            return true;
        }
        return false;
    }, [subAdminList, loadingSubAdmin]);

    if (hideMenu) return null;

    return (
        <Card sx={{ py: 1, px: 2 }}>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'>
                <Typography variant='subtitle2'>Assigned To:</Typography>
                <IconButton size='small' onClick={handleOpenSubAdminList}>
                    <Iconify icon='gravity-ui:gear' />
                </IconButton>
            </Stack>

            <Menu
                open={Boolean(openSubAdminList)}
                anchorEl={openSubAdminList}
                onClose={onSubmit}>
                <Stack
                    sx={{ width: "100%", py: 1, px: 1 }}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'>
                    <Typography variant='subtitle1'>Select SubAdmin</Typography>
                    <IconButton onClick={onSubmit} size='small'>
                        <Iconify icon='ic:round-close' />
                    </IconButton>
                </Stack>
                <Divider />
                <Loop
                    list={subAdminList}
                    render={({ id, username }) => {
                        const isSelected =
                            selectedSubAdmins.findIndex((cid) => cid === id) >
                            -1;

                        return (
                            <MenuItem onClick={selectSubAdmin(id)}>
                                <Ternary
                                    when={isSelected}
                                    then={
                                        <IconButton
                                            size='small'
                                            onClick={handleOpenSubAdminList}
                                            color='primary'>
                                            <Iconify
                                                icon='ic:round-check'
                                                color='primary'
                                            />
                                        </IconButton>
                                    }
                                />
                                {username}
                            </MenuItem>
                        );
                    }}
                />
            </Menu>

            <UsernameList list={selectedUserNames} />
        </Card>
    );
};

export default AssignTo;
