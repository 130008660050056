import Ternary from "src/components/ternary";
import useAuth from "src/hooks/useAuth";
import useResponsive from "src/hooks/useResponsive";
import QuickPopover from "./quickPopOver";

const MobileQuickMenu = () => {
  const { isAdmin } = useAuth();
  const isDesktop = useResponsive("up", "lg");

  return <Ternary when={isAdmin && !isDesktop} then={<QuickPopover />} />;
};

export default MobileQuickMenu;
