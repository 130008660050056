import Ternary from "src/components/ternary";
import useAuth from "src/hooks/useAuth";
import useResponsive from "src/hooks/useResponsive";
import MenuItems from "./menuItems";

const DesktopQuickMenu = () => {
  const { isAdmin } = useAuth();
  const isDesktop = useResponsive("up", "lg");

  return <Ternary when={isAdmin && isDesktop} then={<MenuItems />} />;
};

export default DesktopQuickMenu;
