import { Avatar, Badge, Box, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import MyAvatar from "src/components/MyAvatar";
import { getRankById } from "src/components/tree/components/rank/index.jsx";
import useAuth from "src/hooks/useAuth";
import { PATH_DASHBOARD } from "src/routes/paths";

const RootStyle = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.3, 2),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
    transition: theme.transitions.create("opacity", {
        duration: theme.transitions.duration.shorter,
    }),
}));

NavbarAccount.propTypes = {
    isCollapse: PropTypes.bool,
};
const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 25,
    height: 25,
}));

const userDpname1 = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
};

export default function NavbarAccount({ isCollapse }) {
    const { user, isSubAdmin } = useAuth();
    const [menu] = JSON.parse(localStorage.getItem("menu") || "[]");
    let subAdminRoot = "";
    if (isSubAdmin) {
        const { path, children } = menu?.items[0] || {};
        if (path.includes("dashboard")) {
            subAdminRoot = children[0]?.path;
        } else {
            subAdminRoot = path;
        }
    }
    const { rank_id } = user;
    const { logo } = getRankById(rank_id);
    return (
        <Link
            underline='none'
            color='inherit'
            component={RouterLink}
            to={isSubAdmin ? subAdminRoot : PATH_DASHBOARD.root}>
            <RootStyle
                sx={{
                    ...(isCollapse && {
                        bgcolor: "transparent",
                    }),
                }}>
                <Badge
                    overlap='circular'
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={<SmallAvatar alt='Remy Sharp' src={logo} />}>
                    <MyAvatar src={user.user_profile?.profile_image} />
                </Badge>

                <Box
                    style={userDpname1}
                    sx={{
                        ml: 2,
                        transition: (theme) =>
                            theme.transitions.create("width", {
                                duration: theme.transitions.duration.shorter,
                            }),
                        ...(isCollapse && {
                            ml: 0,
                            width: 0,
                        }),
                    }}>
                    <Typography variant='subtitle2' noWrap style={userDpname1}>
                        {user.user_profile?.first_name}
                    </Typography>
                    <Typography
                        variant='body2'
                        noWrap
                        sx={{ color: "text.secondary" }}
                        style={userDpname1}>
                        {user.username}
                    </Typography>
                </Box>
            </RootStyle>
        </Link>
    );
}
