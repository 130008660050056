import { lazy } from "react";
import Loadable from "src/routes/Loadable";

const Statistics = Loadable(lazy(() => import("src/pages/statistics")));

const SubscriptionsUsers = Loadable(
  lazy(() => import("src/pages/statistics/components/pages/index"))
);
const ProfileUser = Loadable(
  lazy(() => import("src/pages/statistics/components/pages/profileUser"))
);
const TotalSubscription = Loadable(
  lazy(() =>
    import("src/pages/statistics/components/subscription/TotalSubscription")
  )
);
const ActiveSubscription = Loadable(
  lazy(() =>
    import("src/pages/statistics/components/subscription/ActiveSubscription")
  )
);
const ExpiredSubscription = Loadable(
  lazy(() =>
    import("src/pages/statistics/components/subscription/ExpiredSubscription")
  )
);

const statistics = [
  {
    path: "statistics",
    children: [
      { index: true, element: <Statistics /> },
      { path: "total-subscription", element: <TotalSubscription /> },
      { path: "subscriptions", element: <TotalSubscription /> },
      { path: "active-subscription", element: <ActiveSubscription /> },
      { path: "expired-subscription", element: <ExpiredSubscription /> },
      { path: ":product_id", element: <SubscriptionsUsers /> },
      {
        path: "product",
        children: [{ path: ":product_id", element: <Statistics /> }],
      },
      {
        path: "user",
        children: [{ path: ":user_id", element: <ProfileUser /> }],
      },
    ],
  },
];

export default statistics;
