import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Badge,
  Box,
  Button,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useState } from "react";
import Iconify from "src/components/Iconify";
import Logo from "src/components/Logo";
import { IconButtonAnimate } from "src/components/animate";
import CartWidget from "src/components/cartWidget";
import Settings from "src/components/settings";
import SettingsAffiliate from "src/components/settings/SettingsAffiliate";
import Ternary from "src/components/ternary";
import { HEADER } from "src/config";
import useAuth from "src/hooks/useAuth";
import useOffSetTop from "src/hooks/useOffSetTop";
import useResponsive from "src/hooks/useResponsive";
import { RootStyle } from "src/layouts/shared";
import { useUserGuidanceData } from "src/store/user-guidance";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import DesktopQuickMenu from "./components/quickMenu/desktop";
import MobileQuickMenu from "./components/quickMenu/mobile";

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "$spin 8s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  })
);

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [openDisable, setOpenDisable] = useState(false);
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive("up", "lg");

  const [openSettings, setOpenSettings] = useState(false);

  const handleOpenSettings = () => {
    setOpenSettings((prev) => !prev);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };
  const { isAdmin, user } = useAuth();
  const data = useUserGuidanceData();

  const isUserGuidance = Boolean(data);

  const handleOpenGuidance = () => {
    setOpen(true);
  };
  const handleCloseGuidance = () => {
    setOpen(false);
  };

  const disableUserGuidance = () => {
    enqueueSnackbar("Successfully Disabled", { variant: "success" });
    setOpen(false);
    setOpenDisable(false);
  };
  const handledCloseConformation = () => {
    setOpen(false);
    setOpenDisable(false);
  };
  return (
    <>
      <RootStyle
        isCollapse={isCollapse}
        isOffset={isOffset}
        verticalLayout={verticalLayout}
      >
        <Toolbar
          sx={{
            backgroundColor: "background.paper",
            minHeight: "100% !important",
            px: { lg: 5 },
          }}
        >
          {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

          {!isDesktop && (
            <>
              <IconButtonAnimate
                onClick={onOpenSidebar}
                sx={{ mr: 1, color: "text.primary" }}
              >
                <Iconify icon="eva:menu-2-fill" />
              </IconButtonAnimate>
            </>
          )}

          <DesktopQuickMenu />

          <Box sx={{ flexGrow: 1 }} />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            <MobileQuickMenu />

            <Ternary
              when={!user?.is_super_admin}
              then={<SettingsAffiliate />}
            />
            {isUserGuidance && !isAdmin && (
              <IconButtonAnimate onClick={handleOpenGuidance}>
                <Iconify icon="fluent:info-28-filled" />
              </IconButtonAnimate>
            )}

            <CartWidget />
            <NotificationsPopover />

            <Badge
              color="error"
              variant="dot"
              sx={{
                "& .MuiBadge-badge": {
                  top: "7px",
                  right: "7px",
                },
              }}
            >
              <IconButtonAnimate
                className={classes.rotateIcon}
                onClick={() => handleOpenSettings()}
              >
                <Iconify icon="solar:settings-bold-duotone" />
              </IconButtonAnimate>
            </Badge>
            <LanguagePopover />
            <AccountPopover />
          </Stack>
        </Toolbar>
      </RootStyle>

      <Settings handleClose={handleCloseSettings} open={openSettings} />
      {isUserGuidance && !isAdmin && (
        <UserGuidance
          open={open}
          handleCloseGuidance={handleCloseGuidance}
          description={data?.description}
        />
      )}

      <UserGuidanceConformation
        openDisable={openDisable}
        disableUserGuidance={disableUserGuidance}
        handledCloseConformation={handledCloseConformation}
      />
    </>
  );
}
const UserGuidance = ({ open, handleCloseGuidance, description }) => {
  const { palette } = useTheme();
  return open ? (
    <Box
      sx={{
        zIndex: 1,
        color: "#4900ac",
        borderRadius: "8px",
        boxShadow:
          "0px 11px 15px -7px rgba(145, 158, 171, 0.2), 0px 24px 38px 3px rgba(145, 158, 171, 0.14), 0px 9px 46px 8px rgba(145, 158, 171, 0.12)",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        maxHeight: "calc(100% - 64px)",
        maxWidth: "600px",
        width: "calc(100% - 64px)",
        boxShadow: "-40px 40px 80px -8px rgba(0, 0, 0, 0.24)",
        position: "fixed",
        bottom: "20px",
        right: "20px",
        backgroundColor: "#e5d0ff",
        padding: "15px",
        borderLeft: "solid 5px #7558A7",
      }}
    >
      <Typography sx={{ padding: "inherit" }}>{description}</Typography>
      <Button
        onClick={handleCloseGuidance}
        autoFocus
        sx={{
          color: palette.warning.normal,
          position: "fixed",
          bottom: "30px",
          right: "30px",
        }}
      >
        Close
      </Button>
    </Box>
  ) : null;
};
const UserGuidanceConformation = ({
  openDisable,
  disableUserGuidance,
  handledCloseConformation,
}) => {
  const { palette } = useTheme();
  return openDisable ? (
    <Box
      sx={{
        zIndex: 1,
        color: "#212B36",
        borderRadius: "8px",
        boxShadow:
          "0px 11px 15px -7px rgba(145, 158, 171, 0.2), 0px 24px 38px 3px rgba(145, 158, 171, 0.14), 0px 9px 46px 8px rgba(145, 158, 171, 0.12)",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        maxHeight: "calc(100% - 64px)",
        maxWidth: "600px",
        width: "calc(100% - 64px)",
        boxShadow: "-40px 40px 80px -8px rgba(0, 0, 0, 0.24)",
        position: "fixed",
        bottom: "20px",
        right: "20px",
        height: "165px",
        backgroundColor: "white",
        padding: "20px",
      }}
    >
      <Typography sx={{ padding: "inherit" }}>
        Do you want to disable user guidance messages from Bitcointaf ?
      </Typography>
      <Box sx={{ position: "fixed", bottom: "30px", right: "30px" }}>
        <Button
          onClick={handledCloseConformation}
          autoFocus
          sx={{
            color: palette.warning.normal,
          }}
        >
          No
        </Button>

        <Button onClick={disableUserGuidance} variant="contained">
          Yes
        </Button>
      </Box>
    </Box>
  ) : null;
};
