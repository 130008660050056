import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const PreCourseUsers = Loadable(
  lazy(() => import("src/pages/HoldnotsAcademy/PreCourseUsers/PreCourseUser"))
);
const hodlnots = [
  {
    path: "hodlnots-academy",
    children: [
      {
        index: true,
        element: <Navigate to="courses" />,
      },
      {
        path: "courses",
        element: <PreCourseUsers />,
      },
    ],
  },
];
export default hodlnots;
