import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const AssignSubscriptions = Loadable(
  lazy(() => import("src/pages/store/assignSubscriptions/index"))
);

//const AssignSubscriptionFee = Loadable(
//  lazy(() => import("src/pages/store/assignSubscriptions/addProduct/index"))
//);

const AssBusinessBuilder = Loadable(
  lazy(() =>
    import("src/pages/store/assignSubscriptions/addProduct/AddBusinessBuilder")
  )
);

const assignSubscription = [
  {
    path: "assign_subscription",
    children: [
      { element: <Navigate to="add_product" />, index: true },
      { path: "add_product", element: <AssignSubscriptions /> },
      //{ path: "business_builder_fee", element: <AssignSubscriptionFee /> },
      { path: "add_business_builder", element: <AssBusinessBuilder /> },
    ],
  },
];
export default assignSubscription;
