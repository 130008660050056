import React from "react";
import { Navigate } from "react-router";
import isSubAdmin from "src/utils/isSubAdmin";

const HideFromSubAdmin = ({ children, navigate = false }) => {
  if (isSubAdmin()) {
    if (navigate) {
      return <Navigate to="/" />;
    }
    return null;
  }

  return <>{children}</>;
};

export default HideFromSubAdmin;
