import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const ProductCategories = Loadable(
    lazy(() => import("src/pages/store/productCategories/index"))
);

const Products = Loadable(lazy(() => import("src/pages/store/product/index")));

const MaterialCategories = Loadable(
    lazy(() => import("src/pages/store/materialCategories/category/index"))
);

const Material = Loadable(lazy(() => import("src/pages/store/material/index")));

const MaterialAdd = Loadable(
    lazy(() => import("src/pages/store/material/materialAdd/index"))
);

const MaterialView = Loadable(
    lazy(() => import("src/pages/store/material/materialView"))
);

const Events = Loadable(lazy(() => import("src/pages/store/events/index")));

const EventTypes = Loadable(
    lazy(() => import("src/pages/store/events/event-types/index"))
);

const EventsAdd = Loadable(
    lazy(() => import("src/pages/store/events/eventAdd"))
);

const EventsEdit = Loadable(
    lazy(() => import("src/pages/store/events/eventEdit"))
);

const Coupons = Loadable(lazy(() => import("src/pages/store/coupons/index")));

const CouponList = Loadable(
    lazy(() => import("src/pages/store/coupons/components/coupon-list/index"))
);

const CouponHistory = Loadable(
    lazy(() =>
        import("src/pages/store/coupons/components/coupon-history/index")
    )
);
const CouponArchive = Loadable(
    lazy(() => import("src/pages/store/coupons/components/coupon-list/index"))
);

const CouponsAdd = Loadable(
    lazy(() => import("src/pages/store/coupons/views/coupon-add"))
);

const CouponsEdit = Loadable(
    lazy(() => import("src/pages/store/coupons/views/coupon-edit"))
);

const UserReviews = Loadable(
    lazy(() => import("src/pages/store/userReviews/index"))
);

const UserReviewsAdd = Loadable(
    lazy(() => import("src/pages/store/userReviews/reviewAdd"))
);

const UserReviewsView = Loadable(
    lazy(() => import("src/pages/store/userReviews/reviewView"))
);

const AssignSubscriptionsCat = Loadable(
    lazy(() => import("src/pages/store/assignSubscriptionsCat/index"))
);

const AssignSubscriptionsAddProduct = Loadable(
    lazy(() => import("src/pages/store/assignSubscriptions/addProduct/index"))
);

//const BusinessBuilderSubscriptions = Loadable(
//    lazy(() => import("src/pages/store/business-builder-statistics/index"))
//);
//
//const BusinessBuilderSubscriptionStatistics = Loadable(
//    lazy(() =>
//        import(
//            "src/pages/store/business-builder-statistics/subscriptions/index"
//        )
//    )
//);

const ProductVideo = Loadable(
    lazy(() => import("src/pages/store/product/Video"))
);

const DocumentViewEdit = Loadable(
    lazy(() => import("src/pages/store/product/document/index"))
);

const ProductAccess = Loadable(
    lazy(() => import("src/pages/store/product/productAccess"))
);

const ProductQuestions = Loadable(
    lazy(() => import("src/pages/store/product/Questions/Main/index"))
);

const ProductEdit = Loadable(
    lazy(() => import("src/pages/store/product/edit/index"))
);

const ProductCombo = Loadable(
    lazy(() => import("src/pages/store/product/Combo"))
);

const CategoriesNew = Loadable(
    lazy(() => import("src/pages/store/product/Categories"))
);

const SampleDocument = Loadable(
    lazy(() => import("src/pages/store/product/sampleDocument"))
);

const UserUsedCoupon = Loadable(
    lazy(() => import("src/pages/store/coupons/coupon-used-user/index"))
);

const Links = Loadable(
    lazy(() =>
        import("src/pages/store/product/ProductForm/components/link/index")
    )
);

const SpecialPriceSingle = Loadable(
    lazy(() => import("src/pages/store/product/SpecialPrice/single/index"))
);

const SpecialPriceCombo = Loadable(
    lazy(() => import("src/pages/store/product/SpecialPrice/combo/index"))
);

const UserSubscriptions = Loadable(
    lazy(() => import("src/pages/store/userSubscriptions/Index"))
);

const ManageUserSubscription = Loadable(
    lazy(() => import("src/pages/store/userSubscriptions/components/Manage.js"))
);

const NewProduct = Loadable(
    lazy(() => import("src/pages/store/product/add/index"))
);

const TelegramPageLinks = Loadable(
    lazy(() => import("src/pages/store/TelegramPageLink"))
);

const EditTelegramPageLinks = Loadable(
    lazy(() =>
        import("src/pages/store/TelegramPageLink/EditTelegramPageLink.js")
    )
);

const MaterialDocument = Loadable(
    lazy(() => import("src/pages/store/material/documents/index.js"))
);

const MaterialVideos = Loadable(
    lazy(() => import("src/pages/store/material/videos/index.js"))
);

const Cart = Loadable(lazy(() => import("src/pages/store/cart/index")));

const ViewByProduct = Loadable(
    lazy(() => import("src/pages/store/material/view-by-product"))
);

const RecurringSubscriptions = Loadable(
    lazy(() => import("src/pages/store/recurring-subscriptions/index"))
);

const store = [
    {
        path: "store",
        children: [
            { element: <Navigate to='products' replace />, index: true },
            {
                path: "assign_subscriptions_cat",
                element: <AssignSubscriptionsCat />,
            },

            {
                path: "assign_subscriptions/add_product",
                element: <AssignSubscriptionsAddProduct />,
            },
            //{
            //    path: "business-builder-statistics",
            //    children: [
            //        { index: true, element: <BusinessBuilderSubscriptions /> },
            //        {
            //            path: "subscriptions",
            //            element: <BusinessBuilderSubscriptionStatistics />,
            //        },
            //    ],
            //},
            {
                path: "reviews",
                children: [
                    { index: true, element: <UserReviews /> },
                    { path: "add", element: <UserReviewsAdd /> },
                    { path: ":rid", element: <UserReviewsView /> },
                ],
            },
            {
                path: "coupons",
                children: [
                    { index: true, element: <Navigate to='list' /> },
                    {
                        path: "list",
                        element: <Coupons />,
                        children: [
                            { index: true, element: <CouponList /> },
                            { path: "history", element: <CouponHistory /> },
                            { path: "archive", element: <CouponArchive /> },
                        ],
                    },
                    { path: "add", element: <CouponsAdd /> },
                    { path: ":cid", element: <CouponsEdit /> },
                    {
                        path: "coupon-used-user",
                        children: [
                            { index: true, element: <Navigate to={-1} /> },
                            { path: ":id", element: <UserUsedCoupon /> },
                        ],
                    },
                ],
            },
            {
                path: "events",
                children: [
                    { index: true, element: <Events /> },
                    { path: "types", element: <EventTypes /> },
                    { path: "add", element: <EventsAdd /> },
                    { path: ":eid", element: <EventsEdit /> },
                ],
            },
            {
                path: "materials",
                children: [
                    { index: true, element: <Material /> },
                    {
                        path: "categories",
                        element: <MaterialCategories />,
                    },
                    {
                        path: "product/:pid/categories",
                        children: [
                            { path: ":slug", element: <ViewByProduct /> },
                        ],
                    },
                    { path: "add", element: <MaterialAdd /> },
                    {
                        path: ":id",
                        element: <MaterialView />,
                        children: [
                            {
                                path: "documents",
                                element: <MaterialDocument />,
                            },
                            { path: "videos", element: <MaterialVideos /> },
                        ],
                    },
                ],
            },
            {
                path: "products",
                children: [
                    { element: <Products />, index: true },
                    { path: "categories", element: <ProductCategories /> },
                    { path: "add", element: <NewProduct /> },
                    { path: "combo", element: <ProductCombo /> },
                    { path: "category", element: <CategoriesNew /> },
                    {
                        path: ":pid",
                        children: [
                            { element: <ProductEdit />, index: true },
                            { path: "links", element: <Links /> },
                            {
                                path: "single-special-price",
                                element: <SpecialPriceSingle />,
                            },
                            {
                                path: "combo-special-price",
                                element: <SpecialPriceCombo />,
                            },

                            { path: "video", element: <ProductVideo /> },
                            {
                                path: "document",
                                element: <DocumentViewEdit />,
                            },
                            { path: "access", element: <ProductAccess /> },
                            {
                                path: "questions",
                                element: <ProductQuestions />,
                            },
                            { path: "sample", element: <SampleDocument /> },
                        ],
                    },
                ],
            },
            {
                path: "user_subscriptions",
                children: [
                    { element: <UserSubscriptions />, index: true },
                    { path: ":id", element: <ManageUserSubscription /> },
                ],
            },
            {
                path: "telegram_page_links",
                children: [
                    { element: <TelegramPageLinks />, index: true },
                    { path: ":id", element: <EditTelegramPageLinks /> },
                ],
            },
            {
                path: "cart",
                element: <Cart />,
            },
            {
                path: "recurring-subscriptions",
                element: <RecurringSubscriptions />,
            },
        ],
    },
];

export default store;
