import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useParams } from "react-router";
import axiosInstance from "src/utils/axios";
import buildPath from "src/utils/buildPath";

const useAssign = (selectedSubAdmins, onSuccess = () => null) => {
  const { id: ticketId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(async () => {
    const reqData = new FormData();
    selectedSubAdmins.forEach((subAdminId) =>
      reqData.append("subadmin[]", subAdminId)
    );
    reqData.append("_method", "PUT");
    try {
      const { data } = await axiosInstance.post(
        buildPath("api/admin/support-tickets-to-subadmin", ticketId),
        reqData
      );
      enqueueSnackbar(data.message);
      onSuccess();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  }, [selectedSubAdmins, ticketId, enqueueSnackbar]);

  return onSubmit;
};

export default useAssign;
