import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const Binary = Loadable(lazy(() => import("src/pages/genealogy/binary/index")));
const Sponsor = Loadable(
  lazy(() => import("src/pages/genealogy/sponsor/index"))
);
const Tree = Loadable(lazy(() => import("src/pages/genealogy/tree/index")));

const genealogy = [
  {
    path: "genealogy",
    children: [
      { element: <Navigate to="binary" />, index: true },
      {
        path: "binary",
        children: [
          { index: true, element: <Binary /> },
          { path: ":uname", element: <Binary /> },
        ],
      },

      {
        path: "sponsor",
        children: [
          { index: true, element: <Sponsor /> },
          { path: ":uname", element: <Sponsor /> },
        ],
      },

      { path: "tree", element: <Tree /> },
    ],
  },
];

export default genealogy;
