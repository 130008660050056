import PropTypes from "prop-types";
// @mui
import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
// utils

const Index = ({ ticketData }) => {
  const { palette } = useTheme();
  return (
    <div>
      <Card
        sx={{
          py: 3,
          color: palette.primary.main,
        }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Stack width={1} textAlign="center">
            <Typography variant="subtitle2">
              {/* {moment(ticketData.created_at).format("DD MMM YYYY")} */}
              {moment(ticketData.created_at).format("DD MMM YYYY - hh:mm A")}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Created Date
            </Typography>
          </Stack>

          <Stack width={1} textAlign="center">
            <Typography variant="subtitle2">
              {/* {moment(ticketData.updated_at).format("DD MMM YYYY")} */}
              {moment(ticketData.updated_at).format("DD MMM YYYY - hh:mm A")}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Last Response
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </div>
  );
};

export default Index;
