import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const Fee = Loadable(
    lazy(() => import("src/pages/store/assignSubscriptions/addProduct/index"))
);

const Settings = Loadable(
  lazy(() => import("src/pages/settings/business/index"))
);


const Statistics = Loadable(
    lazy(() => import("src/pages/store/business-builder-statistics/index"))
);

const Subscriptions = Loadable(
    lazy(() =>
        import(
            "src/pages/store/business-builder-statistics/subscriptions/index"
        )
    )
);

const BuilderReport = Loadable(
  lazy(() => import("src/pages/reports/builder/index"))
);

const businessBuilder = [
    {
        path: "business-builder",
        children: [
            { element: <Navigate to='fee' />, index: true },

            {
                path: "fee",
                element: <Fee />,
            },
            {
                path: "settings",
                element: <Settings />,
            },
            {
                path: "subscriptions",
                element: <BuilderReport />,
            },
            {
                path: "statistics",
                children: [
                    { index: true, element: <Statistics /> },
                    {
                        path: "subscriptions",
                        element: <Subscriptions />,
                    },
                ],
            },
        ],
    },
];

export default businessBuilder;
