import { ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";
import { capitalCase } from "change-case";
import { Link } from "react-router-dom";
import Iconify from "src/components/Iconify";
import Loop from "src/components/loop";
import menu from "../menu";

const MenuItems = () => {
  return (
    <Stack spacing={0.75}>
      <Loop
        list={menu}
        render={({ icon, title, to }) => (
          <MenuItem
            component={Link}
            to={to}
            sx={{
              mr: 1,
              color: "text.disabled",
            }}
          >
            <ListItemIcon>
              <Iconify fontSize={20} icon={icon} />
            </ListItemIcon>
            <ListItemText
              primary={capitalCase(title)}
              sx={{
                "& > *": {
                  fontWeight: "bold",
                },
              }}
            />
          </MenuItem>
        )}
      />
    </Stack>
  );
};

export default MenuItems;
