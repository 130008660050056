import Ternary from "src/components/ternary";
import useFetchUserGuidancePath from "src/hooks/useFetchUserGuidancePath";
import useSettings from "src/hooks/useSettings";
import Horizontal from "./components/horizontal";
import Vertical from "./components/vertical";

const Layout = () => {
    const config = JSON.parse(localStorage.getItem("menu") || "[]");
    const { themeLayout } = useSettings();
    const verticalLayout = themeLayout === "vertical";
    useFetchUserGuidancePath();

    return (
        <Ternary
            when={verticalLayout}
            then={<Vertical navConfig={config} />}
            otherwise={<Horizontal navConfig={config} />}
        />
    );
};
export default Layout;
