import { useEffect, useState } from "react";

const useSelectSubAdmin = (assignedSubadminIds) => {
  const [selectedSubAdmins, setSelectedSubAdmins] = useState([]);

  const selectSubAdmin = (selectedId) => () => {
    setSelectedSubAdmins((prevState) => {
      const temp = [...prevState];
      const selectedIdIndex = temp.findIndex((id) => id === selectedId);

      if (selectedIdIndex < 0) {
        return [...temp, selectedId];
      }

      temp.splice(selectedIdIndex, 1);
      return temp;
    });
  };

  useEffect(() => {
    if (assignedSubadminIds?.length > 0) {
      setSelectedSubAdmins(assignedSubadminIds);
    }
  }, [assignedSubadminIds]);

  return { selectedSubAdmins, selectSubAdmin };
};

export default useSelectSubAdmin;
