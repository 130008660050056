import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import axiosInstance from "src/utils/axios";
import buildPath from "src/utils/buildPath";

const useGetSubAdminList = () => {
    const { id: ticketId } = useParams();
    const [loadingSubAdmin, setLoadingSubAdmin] = useState(true);
    const [subAdminList, setSubAdminlist] = useState([]);

    const fetchSubAdminList = useCallback(async () => {
        try {
            const { data } = await axiosInstance.get(
                buildPath("/api/admin/subadmin-list", ticketId)
            );
            setSubAdminlist(data?.data);
            setLoadingSubAdmin(false);
        } catch (err) {
            setLoadingSubAdmin(false);
            console.error(err);
        }
    }, [ticketId]);

    useEffect(() => {
        fetchSubAdminList();
    }, [fetchSubAdminList]);

    return { loadingSubAdmin, subAdminList, fetchSubAdminList };
};

export default useGetSubAdminList;
