import buildPath from "src/utils/buildPath";
import objectToQueryString from "src/utils/objectToQueryString";
import { encodeObjectToQueryString } from "src/utils/queryString";
import squashPathAndQueryString from "src/utils/squashPathAndQueryString";
import stripNullFromObject from "src/utils/strip-null-from-object";

export function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/admin";
const ROOTS_USER = "/user";

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, "/login"),
    register: path(ROOTS_AUTH, "/register"),
    loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
    registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
    verify: path(ROOTS_AUTH, "/verify"),
    resetPassword: path(ROOTS_AUTH, "/reset-password"),
    newpassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_PAGE = {
    comingSoon: "/coming-soon",
    maintenance: "/maintenance",
    giftaccept: "/gift-accept",
    expired: "/expired",
    pricing: "/pricing",
    payment: "/payment",
    about: "/about-us",
    contact: "/contact-us",
    page404: "/404",
    page500: "/500",
    components: "/components",
};

const isTypeAdmin = () => {
    const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
    const isSubAdmin = JSON.parse(localStorage.getItem("isSubAdmin"));

    return isAdmin || isSubAdmin;
};

export const PATH_COMMON = {
    binaryByUser: (uname) =>
        path(
            isTypeAdmin() ? ROOTS_DASHBOARD : `${ROOTS_USER}/business-builder`,
            `/genealogy/binary/${uname}`
        ),

    resetBinaryTree: path(
        isTypeAdmin() ? ROOTS_DASHBOARD : `${ROOTS_USER}/business-builder`,
        `/genealogy/binary`
    ),

    sponsorByUser: (uname) =>
        path(
            isTypeAdmin() ? ROOTS_DASHBOARD : `${ROOTS_USER}/business-builder`,
            `/genealogy/sponsor/${uname}`
        ),

    resetSponsorTree: path(
        isTypeAdmin() ? ROOTS_DASHBOARD : `${ROOTS_USER}/business-builder`,
        `/genealogy/sponsor`
    ),
};

export const PATH_DASHBOARD = {
    root: path(ROOTS_DASHBOARD, "/dashboard/business"),
    businessBuilder: {
        root: path(ROOTS_DASHBOARD, "/business-builder"),
        fee: path(ROOTS_DASHBOARD, "/business-builder/fee"),
        subscriptions: path(ROOTS_DASHBOARD, "/business-builder/subscriptions"),
        settings: path(ROOTS_DASHBOARD, "/business-builder/settings"),
        statistics: path(ROOTS_DASHBOARD, "/business-builder/statistics"),
        statisticsSubscription: (params = {}) =>
            squashPathAndQueryString(
                path(
                    ROOTS_DASHBOARD,
                    "/business-builder/statistics/subscriptions"
                ),
                objectToQueryString(params)
            ),
    },
    business: {
        affiliate: path(
            ROOTS_DASHBOARD,
            "/dashboard/business/affiliate-expense"
        ),
    },
    network: path(ROOTS_DASHBOARD, "/dashboard/network"),
    activeSubscriptions: {
        root: path(ROOTS_DASHBOARD, "/subscriptions/user"),
    },
    tokens: {
        root: path(ROOTS_DASHBOARD, "/tokens"),
    },
    whisperDrop: {
        root: path(ROOTS_DASHBOARD, "/whisper-drop"),
    },
    statistics: {
        root: path(ROOTS_DASHBOARD, "/statistics"),
        total_subscription: path(
            ROOTS_DASHBOARD,
            "/statistics/total-subscription"
        ),
        subscriptions: (params = {}) =>
            squashPathAndQueryString(
                buildPath(ROOTS_DASHBOARD, "statistics/subscriptions"),
                encodeObjectToQueryString(params)
            ),
        active_subscriptions: (params = {}) =>
            squashPathAndQueryString(
                buildPath(ROOTS_DASHBOARD, "statistics/active-subscription"),
                encodeObjectToQueryString(params)
            ),
        expired_subscriptions: (params = {}) =>
            squashPathAndQueryString(
                buildPath(ROOTS_DASHBOARD, "statistics/expired-subscription"),
                encodeObjectToQueryString(params)
            ),
        active_subscription: path(
            ROOTS_DASHBOARD,
            "/statistics/active-subscription"
        ),
        expired_subscription: path(
            ROOTS_DASHBOARD,
            "/statistics/expired-subscription"
        ),
        productSubscriptions_users: (product_id) =>
            path(ROOTS_DASHBOARD, `/statistics/${product_id}`),
        product: {
            root: (product_id) =>
                path(ROOTS_DASHBOARD, `/statistics/product/${product_id}`),
        },

        profileUser: {
            root: (user_id) =>
                path(ROOTS_DASHBOARD, `/statistics/user/${user_id}`),
        },
    },

    general: {
        app: path(ROOTS_DASHBOARD, "/dashboard"),
        ecommerce: path(ROOTS_DASHBOARD, "/ecommerce"),
        analytics: path(ROOTS_DASHBOARD, "/analytics"),
        banking: path(ROOTS_DASHBOARD, "/banking"),
        booking: path(ROOTS_DASHBOARD, "/booking"),
        home: path(ROOTS_DASHBOARD, "/home"),
        test: path(ROOTS_DASHBOARD, "/test"),
    },
    mail: {
        root: path(ROOTS_DASHBOARD, "/communication/mails"),
        all: path(ROOTS_DASHBOARD, "/communication/mails/all"),
        replay: (id) =>
            path(ROOTS_DASHBOARD, `/communication/mails/replay/${id}`),
    },
    chat: {
        root: path(ROOTS_DASHBOARD, "/chat"),
        new: path(ROOTS_DASHBOARD, "/chat/new"),
        view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
    },
    calendar: path(ROOTS_DASHBOARD, "/calendar"),
    kanban: path(ROOTS_DASHBOARD, "/kanban"),
    user: {
        root: path(ROOTS_DASHBOARD, "/user"),
        new: path(ROOTS_DASHBOARD, "/user/new"),
        list: path(ROOTS_DASHBOARD, "/user/list"),
        cards: path(ROOTS_DASHBOARD, "/user/cards"),
        profile: path(ROOTS_DASHBOARD, "/profile"),
        account: path(ROOTS_DASHBOARD, "/account"),
        edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
        demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    },
    eCommerce: {
        root: path(ROOTS_DASHBOARD, "/e-commerce"),
        shop: path(ROOTS_DASHBOARD, "/e-commerce/shop"),
        list: path(ROOTS_DASHBOARD, "/e-commerce/list"),
        checkout: path(ROOTS_DASHBOARD, "/e-commerce/checkout"),
        new: path(ROOTS_DASHBOARD, "/e-commerce/product/new"),
        view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
        edit: (name) =>
            path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
        demoEdit: path(
            ROOTS_DASHBOARD,
            "/e-commerce/product/nike-blazer-low-77-vintage/edit"
        ),
        demoView: path(
            ROOTS_DASHBOARD,
            "/e-commerce/product/nike-air-force-1-ndestrukt"
        ),
    },
    invoice: {
        root: path(ROOTS_DASHBOARD, "/invoice"),
        list: path(ROOTS_DASHBOARD, "/invoice/list"),
        new: path(ROOTS_DASHBOARD, "/invoice/new"),
        view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
        demoEdit: path(
            ROOTS_DASHBOARD,
            "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit"
        ),
        demoView: path(
            ROOTS_DASHBOARD,
            "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5"
        ),
        userInvoice: path(ROOTS_DASHBOARD, "/invoice/user_invoices"),
        businessBuilder: (id) =>
            path(ROOTS_DASHBOARD, buildPath("/invoice/business-builder", id)),
    },

    blog: {
        root: path(ROOTS_DASHBOARD, "/blog"),
        posts: path(ROOTS_DASHBOARD, "/blog/posts"),
        new: path(ROOTS_DASHBOARD, "/blog/new"),
        view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
        demoView: path(
            ROOTS_DASHBOARD,
            "/blog/post/apply-these-7-secret-techniques-to-improve-event"
        ),
    },
    genealogy: {
        root: path(ROOTS_DASHBOARD, "/genealogy"),
        binary: path(ROOTS_DASHBOARD, "/genealogy/binary"),
        binaryByUser: (uname) =>
            path(
                JSON.parse(localStorage.isAdmin) ? ROOTS_DASHBOARD : ROOTS_USER,
                `/genealogy/binary/${uname}`
            ),
        sponsor: path(ROOTS_DASHBOARD, "/genealogy/sponsor"),
        tree: path(ROOTS_DASHBOARD, "/genealogy/tree"),
    },
    financial: {
        root: path(ROOTS_DASHBOARD, "/financial"),
        ewallet: path(ROOTS_DASHBOARD, "/financial/e-wallet"),
        eWallet: (params) =>
            squashPathAndQueryString(
                path(ROOTS_DASHBOARD, "/financial/e-wallet"),
                objectToQueryString(params)
            ),
        deposit_wallet: path(ROOTS_DASHBOARD, "/financial/deposit-wallet"),
        fund_credits: path(ROOTS_DASHBOARD, "/financial/fund-credits"),
        payout: path(ROOTS_DASHBOARD, "/financial/payout"),
        affiliate: path(ROOTS_DASHBOARD, "/financial/affiliate-dashboard"),
        report: {
            root: path(ROOTS_DASHBOARD, "/financial/report"),
            analyst: {
                root: path(ROOTS_DASHBOARD, "/financial/report/analyst"),
                edit: (id) =>
                    path(ROOTS_DASHBOARD, `/financial/report/analyst/${id}`),
            },
            adminExpense: {
                root: path(
                    ROOTS_DASHBOARD,
                    "/financial/report/add-admin-expense"
                ),
                edit: (id) =>
                    path(
                        ROOTS_DASHBOARD,
                        `/financial/report/add-admin-expense/${id}`
                    ),
            },
            product: {
                root: path(
                    ROOTS_DASHBOARD,
                    "/financial/report/analyst-financial-report"
                ),
                addExpense: path(
                    ROOTS_DASHBOARD,
                    "/financial/report/analyst-financial-report/add"
                ),
                edit: (id) =>
                    path(
                        ROOTS_DASHBOARD,
                        `/financial/report/analyst-financial-report/${id}`
                    ),
                invoice: (id) =>
                    path(
                        ROOTS_DASHBOARD,
                        `/financial/report/analyst-financial-report/invoice/${id}`
                    ),
            },
        },
        invoice: (id) =>
            path(
                ROOTS_DASHBOARD,
                `/financial/analyst-financial-report/invoice/${id}`
            ),
    },

    communication: {
        root: path(ROOTS_DASHBOARD, "/communication"),
        mail: {
            sendTo: (email = "") =>
                squashPathAndQueryString(
                    buildPath(ROOTS_DASHBOARD, "communication/mails/compose"),
                    objectToQueryString({ email })
                ),
        },
        blogCategories: path(ROOTS_DASHBOARD, "/communication/blog/categories"),
        draft_blog: path(ROOTS_DASHBOARD, "/communication/blog/posts/drafts"),
        help_center: path(ROOTS_DASHBOARD, "/communication/help-center"),
        help_center_tickets: path(
            ROOTS_DASHBOARD,
            "/communication/help-center/tickets"
        ),
        addTicket: path(
            ROOTS_DASHBOARD,
            "/communication/help-center/tickets/add"
        ),
        viewTickets: (id) =>
            path(
                ROOTS_DASHBOARD,
                `/communication/help-center/tickets/view/${id}`
            ),
        edit: (id) =>
            path(
                ROOTS_DASHBOARD,
                `/communication/help-center/tickets/edit/${id}`
            ),
        newBlog: path(ROOTS_DASHBOARD, "/communication/blog/new"),
        editBlog: (bid) => path(ROOTS_DASHBOARD, `/communication/blog/${bid}`),
        viewBlog: (slug) =>
            path(ROOTS_DASHBOARD, `/communication/blog/posts/${slug}`),
        blog: path(ROOTS_DASHBOARD, "/communication/blog"),
        com_faqs: path(ROOTS_DASHBOARD, "/communication/faqs"),
        mails: path(ROOTS_DASHBOARD, "/communication/mails"),
        com_support_department: path(
            ROOTS_DASHBOARD,
            "/communication/support/department"
        ),
        com_support_categories: path(
            ROOTS_DASHBOARD,
            "/communication/support/categories"
        ),
        com_support_priorities: path(
            ROOTS_DASHBOARD,
            "/communication/support/priorities"
        ),
        com_support_canned_response: path(
            ROOTS_DASHBOARD,
            "/communication/support/canned-response"
        ),
        com_support_tickets: path(
            ROOTS_DASHBOARD,
            "/communication/support/tickets"
        ),
        com_article_categories: path(
            ROOTS_DASHBOARD,
            "/communication/com_article_categories"
        ),
        com_article: path(ROOTS_DASHBOARD, "/communication/articles"),
        article_categories: path(
            ROOTS_DASHBOARD,
            "/communication/articles/categories"
        ),
    },
    tools: {
        root: path(ROOTS_DASHBOARD, "/tools"),
        documents: path(ROOTS_DASHBOARD, "/tools/documents"),
        videos: path(ROOTS_DASHBOARD, "/tools/videos"),
    },
    dashboard: {
        root: path(ROOTS_DASHBOARD, "/dashboard"),
        business: path(ROOTS_DASHBOARD, "/dashboard/business"),
        view: (id) => path(ROOTS_DASHBOARD, `/dashboard/business/${id}`),
        salesReport: {
            root: path(ROOTS_DASHBOARD, "/dashboard/business/salesReport"),
            rootWithFilter: (params) =>
                squashPathAndQueryString(
                    path(ROOTS_DASHBOARD, "/dashboard/business/salesReport"),
                    objectToQueryString(params)
                ),
            withFilter: (params) =>
                squashPathAndQueryString(
                    path(ROOTS_DASHBOARD, "/dashboard/business/salesReport"),
                    objectToQueryString(params)
                ),
            monthly_product_sale_report: path(
                ROOTS_DASHBOARD,
                "/dashboard/business/salesReport/monthly-product-sales-report"
            ),
            monthlyProductSaleReport: (params) =>
                squashPathAndQueryString(
                    path(
                        ROOTS_DASHBOARD,
                        "/dashboard/business/salesReport/monthly-product-sales-report"
                    ),
                    objectToQueryString(params)
                ),
            productPurchasedUser: (product_id) =>
                path(
                    ROOTS_DASHBOARD,
                    `/dashboard/business/salesReport/${product_id}`
                ),
        },

        network: path(ROOTS_DASHBOARD, "/dashboard/network"),
        networkBonusDetails: path(
            ROOTS_DASHBOARD,
            "/dashboard/network/monthly-network-bonus"
        ),
    },
    hodlnots: {
        root: path(ROOTS_DASHBOARD, "/hodlnots-academy"),
        preCourseUsers: path(ROOTS_DASHBOARD, "/hodlnots-academy/courses"),
        // network: path(ROOTS_DASHBOARD, "/members/network"),
        // holdingTank: path(ROOTS_DASHBOARD, "/members/holding-tank"),
        // member_profile: path(ROOTS_DASHBOARD, "/members/profile"),
    },
    members: {
        root: path(ROOTS_DASHBOARD, "/members"),
        network: path(ROOTS_DASHBOARD, "/members/network"),
        businessBuilder: path(ROOTS_DASHBOARD, "/members/business-builder"),

        holdingTank: path(ROOTS_DASHBOARD, "/members/holding-tank"),
        member_profile: path(ROOTS_DASHBOARD, "/members/profile"),
    },
    subscriptions: {
        root: path(ROOTS_DASHBOARD, "/subscriptions"),
        add_product: path(ROOTS_DASHBOARD, "/subscriptions/add_product"),
        add_business: path(ROOTS_DASHBOARD, "/subscriptions/add_business"),
    },
    subAdmin: {
        root: path(ROOTS_DASHBOARD, "/sub-admin"),
        sub_admins: path(ROOTS_DASHBOARD, "/sub-admin"),
        sub_admin_user: path(ROOTS_DASHBOARD, "/sub-admin/sub_admin_user"),
        add_sub_admin: path(ROOTS_DASHBOARD, "/sub-admin/add"),
        add_user_group: path(ROOTS_DASHBOARD, "/sub-admin/group/add"),
    },
    holdingTank: {
        root: path(ROOTS_DASHBOARD, "/holding-tank"),
    },
    store: {
        root: path(ROOTS_DASHBOARD, "/store"),
        product_categories: path(ROOTS_DASHBOARD, "/store/products/categories"),
        products: path(ROOTS_DASHBOARD, "/store/products"),
        material_categories: path(
            ROOTS_DASHBOARD,
            "/store/materials/categories"
        ),
        material: path(ROOTS_DASHBOARD, "/store/materials"),
        material_add: path(ROOTS_DASHBOARD, "/store/materials/add"),
        material_view: path(ROOTS_DASHBOARD, "/store/materials"),
        material: {
            root: path(ROOTS_DASHBOARD, "/store/materials"),
            view: (id, name, sub = "documents") =>
                path(
                    ROOTS_DASHBOARD,
                    `/store/materials/${id}/${sub}?product=${name}`
                ),
            viewDocumentCategory: (id) => buildPath(ROOTS_DASHBOARD),

            viewCategoryByProduct: (pid, slug) =>
                buildPath(
                    ROOTS_DASHBOARD,
                    "store/materials/product",
                    pid,
                    "categories",
                    slug
                ),
        },
        events: path(ROOTS_DASHBOARD, "/store/events"),
        events_type: path(ROOTS_DASHBOARD, "/store/events/types"),
        events_add: path(ROOTS_DASHBOARD, "/store/events/add"),
        events_edit: path(ROOTS_DASHBOARD, "/store/events"),
        user_reviews: path(ROOTS_DASHBOARD, "/store/reviews"),
        user_reviews_add: path(ROOTS_DASHBOARD, "/store/reviews/add"),
        user_reviews_edit: path(ROOTS_DASHBOARD, "/store/reviews"),
        assign_subscriptions_cat: path(
            ROOTS_DASHBOARD,
            "/store/assign_subscriptions_cat"
        ),

        // assign_subscriptions_add_product: path(
        //   ROOTS_DASHBOARD,
        //   "/store/assign_subscriptions/add_product"
        // ),
        assign_subscriptions: path(
            ROOTS_DASHBOARD,
            "/store/assign_subscriptions"
        ),

        businessBuilderStatistics: path(
            ROOTS_DASHBOARD,
            "/store/business-builder-statistics"
        ),
        businessBuilderStatisticsSubscription: (params) =>
            squashPathAndQueryString(
                path(
                    ROOTS_DASHBOARD,
                    "/store/business-builder-statistics/subscriptions"
                ),
                objectToQueryString(stripNullFromObject(params))
            ),
        businessBuilderSubscriptions: (params) =>
            squashPathAndQueryString(
                path(ROOTS_DASHBOARD, "/store/business-builder-statistics"),
                objectToQueryString(params)
            ),
        // user_subscriptions:path(ROOTS_DASHBOARD,"/store/user_subscriptions"),
        sales: path(ROOTS_DASHBOARD, "/store/sales"),
        materials_management: path(
            ROOTS_DASHBOARD,
            "/store/materials_management"
        ),
        business_builder: path(ROOTS_DASHBOARD, "/store/business_builder"),
        review_management: path(ROOTS_DASHBOARD, "/store/review_management"),
        cart: path(ROOTS_DASHBOARD, "/store/cart"),
        user_subscriptions: path(ROOTS_DASHBOARD, "/store/user_subscriptions"),
        telegram_page: path(ROOTS_DASHBOARD, "/store/telegram_page"),
        coupons: path(ROOTS_DASHBOARD, "/store/coupons"),
        couponsList: path(ROOTS_DASHBOARD, "/store/coupons/list"),
        coupons_add: path(ROOTS_DASHBOARD, "/store/coupons/add"),
        coupons_edit: path(ROOTS_DASHBOARD, "/store/coupons"),
        coupon_used_user: path(
            ROOTS_DASHBOARD,
            "/store/coupons/coupon-used-user"
        ),
        product_access: path(ROOTS_DASHBOARD, "/store/product_access"),
        product_questions: path(ROOTS_DASHBOARD, "/store/product_questions"),
        product_edit: path(ROOTS_DASHBOARD, "/store/products"),
        product_add: path(ROOTS_DASHBOARD, "/store/products/add"),
        product_combo: path(ROOTS_DASHBOARD, "/store/products/combo"),
        product_newcategory: path(ROOTS_DASHBOARD, "/store/products/category"),
        sample_document: path(ROOTS_DASHBOARD, "/store/sample_document"),
        telegram_page_links: path(
            ROOTS_DASHBOARD,
            "/store/telegram_page_links"
        ),
        recurringSubscription: path(
            ROOTS_DASHBOARD,
            "/store/recurring-subscriptions"
        ),
    },
    assignSubscription: {
        root: path(ROOTS_DASHBOARD, "/assign_subscription"),
        add_product: path(ROOTS_DASHBOARD, "/assign_subscription/add_product"),
        business_builder_fee: path(
            ROOTS_DASHBOARD,
            "/assign_subscription/business_builder_fee"
        ),
        assign_subscriptions_add_product: path(
            ROOTS_DASHBOARD,
            "/assign_subscription/add_business_builder"
        ),
    },
    settings: {
        root: path(ROOTS_DASHBOARD, "/settings"),
        network: path(ROOTS_DASHBOARD, "/settings/network"),
        brand: path(ROOTS_DASHBOARD, "/settings/brand"),
        business: path(ROOTS_DASHBOARD, "/settings/business"),
        withdrawal: path(ROOTS_DASHBOARD, "/settings/withdrawal"),
        business_builder: path(ROOTS_DASHBOARD, "/settings/business_builder"),
        app_settings: path(ROOTS_DASHBOARD, "/settings/app_settings"),
        email_settings: path(ROOTS_DASHBOARD, "/settings/email_settings"),
        terms_conditions: path(ROOTS_DASHBOARD, "/settings/terms_conditions"),
        user_guidance: path(ROOTS_DASHBOARD, "/settings/user_guidance"),
        get_started: path(ROOTS_DASHBOARD, "/settings/get_started"),
        editGuidance: (gid) => path(ROOTS_DASHBOARD, `/settings/${gid}`),
        // email_settings: path(ROOTS_DASHBOARD, "/settings/get_started"),
        termsAndCondition: path(ROOTS_DASHBOARD, "/settings/terms&condition"),
    },
    report: {
        root: path(ROOTS_DASHBOARD, "/report"),
        builder: path(ROOTS_DASHBOARD, "/report/builder"),
        // old
        fundCredit: (arg) => buildPath(ROOTS_DASHBOARD, "report/fund", arg),
        fund_credit: path(ROOTS_DASHBOARD, "/report/fund/credit"),
        gift: path(ROOTS_DASHBOARD, "/report/gift"),
        joining: path(ROOTS_DASHBOARD, "/report/joining"),
        member_income: path(ROOTS_DASHBOARD, "/report/income"),
        top_earners: path(ROOTS_DASHBOARD, "/report/earners"),
        payout_report: path(ROOTS_DASHBOARD, "/report/payout"),
        sales_report: path(ROOTS_DASHBOARD, "/report/sales"),
        sales: (params = {}) =>
            squashPathAndQueryString(
                path(ROOTS_DASHBOARD, "/report/sales"),
                objectToQueryString(params)
            ),
        builder_subscription: path(
            ROOTS_DASHBOARD,
            "/report/builder_subscription"
        ),
        point_history: path(ROOTS_DASHBOARD, "/report/point/history"),
        transaction_report: path(ROOTS_DASHBOARD, "/report/transaction"),
    },
};

export const PATH_USER = {
    user_dashboard: path(ROOTS_USER, "/dashboard"),
    root: path(ROOTS_USER, "/dashboard"),
    test: path(ROOTS_USER, "/test"),
    events: path(ROOTS_USER, "/events"),
    profile: {
        root: path(ROOTS_USER, "/profile"),
        settings: path(ROOTS_USER, "/profile/settings"),
    },
    telegram: path(ROOTS_USER, "/telegram"),
    subscriptions: {
        root: (query = { status: "active" }) =>
            squashPathAndQueryString(
                path(ROOTS_USER, "/subscriptions"),
                objectToQueryString(query)
            ),
        whisperDrop: { root: path(ROOTS_USER, `/subscriptions/whisper-drop`) },
        blog: (id) => path(ROOTS_USER, `/subscriptions/blogs/${id}`),
        view:
            (id, pname) =>
            (subPath = "") =>
                path(ROOTS_USER, `/subscriptions/${pname}/${id}/${subPath}`),
    },
    findSponsor: {
        root: path(ROOTS_USER, "/sponsor"),
        sponsor: path(ROOTS_USER, "/sponsor/find"),
    },
    onlineStore: {
        root: path(ROOTS_USER, "/online-store"),
        productSubscription: {
            root: path(ROOTS_USER, "/online-store/product-subscription"),
            checkout: path(ROOTS_USER, "/checkout"),

            view: (id, name) =>
                path(
                    ROOTS_USER,
                    `/online-store/product-subscription/${id}/${name}`
                ),
        },
        checkout: path(ROOTS_USER, "/online-store/checkout"),
    },
    my_orders: {
        root: path(ROOTS_USER, "/online-store/my-orders"),
        view: (id) => path(ROOTS_USER, `/online-store/my-orders/${id}`),
    },
    mails: {
        root: path(ROOTS_USER, "/help-center/mails"),
        all: path(ROOTS_USER, "/help-center/mails/all"),
        replay: (id) => path(ROOTS_USER, `/help-center/mails/replay/${id}`),
    },

    blogs: {
        root: path(ROOTS_USER, "/blogs"),
        category: (query) =>
            squashPathAndQueryString(
                path(ROOTS_USER, "/blogs"),
                objectToQueryString(query)
            ),
        new: path(ROOTS_USER, "/blogs/new"),
        view: (param) => path(ROOTS_USER, `/blogs/${param}`),
    },
    business_builder: {
        financial: {
            depositWallet: path(
                ROOTS_USER,
                "/business-builder/financial/deposit-wallet"
            ),
            eWallet: path(ROOTS_USER, "/business-builder/financial/e-wallet"),
            myPayout: path(ROOTS_USER, "/business-builder/financial/payout"),
            myFundTransfers: path(
                ROOTS_USER,
                "/business-builder/financial/funds-transfer"
            ),
        },
        genealogy: {
            binary: path(ROOTS_USER, "/business-builder/genealogy/binary"),
            sponsor: path(ROOTS_USER, "/business-builder/genealogy/sponsor"),
            binaryLegSettings: path(
                ROOTS_USER,
                "/business-builder/genealogy/binary-leg"
            ),
            affiliate: path(
                ROOTS_USER,
                "/business-builder/genealogy/affiliate"
            ),
            referralDetails: (id) =>
                path(ROOTS_USER, `/business-builder/genealogy/affiliate/${id}`),
        },
        root: path(ROOTS_USER, "/business-builder"),
        subscriptions: {
            root: path(ROOTS_USER, "/business-builder/subscriptions"),
            view: (id) =>
                path(
                    ROOTS_USER,
                    `/business-builder/subscriptions/invoice/${id}`
                ),
        },
        history: path(ROOTS_USER, "/business-builder/history"),
        materials: {
            root: path(ROOTS_USER, "/business-builder/materials"),
            view: (view = "blog") =>
                path(ROOTS_USER, `/business-builder/materials/${view}`),
        },
    },
    genealogy: {
        root: path(ROOTS_USER, "/genealogy"),
        binary: path(ROOTS_USER, "/genealogy/binary"),
        sponsor: path(ROOTS_USER, "/genealogy/sponsor"),
        affiliate: path(ROOTS_USER, "/genealogy/affiliate"),
        referralDetails: (id) => path(ROOTS_USER, `/genealogy/${id}`),
        binaryLeg: path(ROOTS_USER, "/genealogy/binaryLeg"),
    },

    financial: {
        root: path(ROOTS_USER, "/financial"),
        eWallet: path(ROOTS_USER, "/financial/e-wallet"),
        depositWallet: path(ROOTS_USER, "/financial/deposit-wallet"),
        fundsTransfer: path(ROOTS_USER, "/financial/funds-transfer"),
        payout: path(ROOTS_USER, "/financial/payout"),
        coinAddress: path(ROOTS_USER, "/financial/coin-address"),
    },
    helpCenter: {
        root: path(ROOTS_USER, "/help-center"),
        tickets: path(ROOTS_USER, "/help-center/tickets"),
        faq: {
            root: path(ROOTS_USER, "/help-center/faqs"),
            view: (view) => path(ROOTS_USER, `/help-center/faqs/${view}`),
        },
        knowledgeBase: {
            root: path(ROOTS_USER, `/help-center/knowledge-base`),
            question: (id) =>
                path(ROOTS_USER, `/help-center/knowledge-base/${id}`),
        },
        mails: {
            root: path(ROOTS_USER, "/help-center/mails"),
            all: path(ROOTS_USER, "/help-center/mails/inbox"),
            sendTo: (email = "") =>
                squashPathAndQueryString(
                    buildPath(ROOTS_USER, "help-center/mails/compose"),
                    objectToQueryString({ email })
                ),
        },
        videos: {
            root: path(ROOTS_USER, "/help-center/videos"),
        },
        doc: {
            root: path(ROOTS_USER, "/help-center/documents"),
        },
        view: (label) => path(ROOTS_USER, `/help-center/mails/${label}`),
        createTicket: {
            view: path(ROOTS_USER, "/help-center/create-ticket"),
            subCategory: (category = "open") =>
                path(ROOTS_USER, `/help-center/create-ticket/${category}`),
            contactSupport: path(
                ROOTS_USER,
                "/help-center/create-ticket/contact-support"
            ),
            otherSupport: path(
                ROOTS_USER,
                "/help-center/create-ticket/other-support"
            ),
        },
        mySupportTicket: path(
            ROOTS_USER,
            "/help-center/create-ticket/my-support-ticket"
        ),
    },
    incomeReport: {
        root: path(ROOTS_USER, "/income-report"),
    },
    missedPoints: {
        root: path(ROOTS_USER, "/missed-points"),
    },
    giftAccept: {
        root: path(ROOTS_USER, "/gift-accept"),
    },
};

export const USER_ROUTES = [
    "/dashboard",
    "/dashboard",
    "/events",
    "/profile",
    "/telegram",
    "/subscriptions",
    "/subscriptions/blogs?type=subscriptions&id=${id}",
    "/subscriptions/view/${id}/${subPath}",
    "/online-store",
    "/online-store/product-subscription",
    "/online-store/product-subscription/checkout",
    "/online-store/product-subscription/${name}",
    "/online-store/my-orders/${id}",
    "/online-store/checkout",
    "/online-store/my-orders",
    "/blogs",
    "/blogs/new",
    "/blogs/${param}",
    "/business-builder",
    "/business-builder/subscriptions",
    "/business-builder/history",
    "/business-builder/materials",
    "/business-builder/materials/${view}",
    "/genealogy",
    "/genealogy/binary",
    "/genealogy/sponsor",
    "/genealogy/affiliate",
    "/genealogy/binaryLeg",
    "/financial",
    "/financial/e-wallet",
    "/financial/deposit-wallet",
    "/financial/funds-transfer",
    "/financial/payout",
    "/financial/coin-address",
    "/help-center",
    "/help-center/tickets",
    "/help-center/faqs",
    "/help-center/mails",
    "/help-center/mails/all",
    "/help-center/create-ticket",
    "/help-center/create-ticket/contact-support",
    "/help-center/create-ticket/other-support",
    "/help-center/create-ticket/my-support-ticket",
    "/help-center/faqs/${view}",
    "/help-center/knowledge-base",
    "/help-center/knowledge-base/${view}",
    "/help-center/mails/${label}",
    "/help-center/create-ticket/${category}",
    "/income-report",
    "/missed-points",
    "/gift-accept",
];

export const PATH_DOCS = "";
