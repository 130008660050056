import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

import HideFromSubAdmin from "src/components/hideFromSubAdmin";

const Business = Loadable(
  lazy(() => import("src/pages/dashboard/business/index"))
);

const AffilateExpense = Loadable(
  lazy(() => import("src/pages/dashboard/affilate/index"))
);

const Network = Loadable(lazy(() => import("src/pages/dashboard/network")));

const LatestSaleInvoice = Loadable(
  lazy(() => import("src/pages/dashboard/invoice-details/index"))
);

const MonthlySalesReport = Loadable(
  lazy(() => import("src/pages/dashboard/components/SalesDetails/Index"))
);

const NetworkBonusDetails = Loadable(
  lazy(() =>
    import("src/pages/dashboard/network/components/NetWorkBonusDetails/Index")
  )
);

const ProductSalesReport = Loadable(
  lazy(() =>
    import("src/pages/dashboard/components/SalesDetails/ProductSalesReport")
  )
);

const ProductPurchasedUser = Loadable(
  lazy(() =>
    import(
      "src/pages/dashboard/components/SalesDetails/ProductSalesReport/components/ProductPurchasedUser"
    )
  )
);

const dashboard = [
  {
    path: "dashboard",
    children: [
      { index: true, element: <Navigate to="business" /> },
      {
        path: "business",
        children: [
          { index: true, element: <Business /> },
          {
            path: "affiliate-expense",
            element: (
              <HideFromSubAdmin navigate>
                <AffilateExpense />
              </HideFromSubAdmin>
            ),
          },
          { path: ":id", element: <LatestSaleInvoice /> },
          {
            path: "salesReport",
            children: [
              { index: true, element: <MonthlySalesReport /> },
              {
                path: "monthly-product-sales-report",
                element: <ProductSalesReport />,
              },
              {
                path: ":product_id",
                element: <ProductPurchasedUser />,
              },
            ],
          },
        ],
      },

      {
        path: "network",
        children: [
          { index: true, element: <Network /> },
          {
            path: "monthly-network-bonus",
            element: <NetworkBonusDetails />,
          },
        ],
      },
    ],
  },
];

export default dashboard;
