import { Navigate } from "react-router";
import buildPath from "src/utils/buildPath";
import assignSubscription from "./assignSubscription";
import communication from "./communication";
import dashboard from "./dashboard";
import financial from "./financial";
import genealogy from "./genealogy";
import hodlnots from "./hodlnots";
import invoice from "./invoice";
import members from "./members";
import reports from "./reports";
import settings from "./settings";
import statistics from "./statistics";
import store from "./store";
import subAdmin from "./subAdmin";
import subscriptions from "./subscriptions";
import tools from "./tools";
import user from "./user";
import walletAddress from "./wallet-address";
import businessBuilder from "./business-builder";
import { QuizTwoTone } from "@mui/icons-material";

const test = {
    communication: communication,
    dashboard: dashboard,
    financial: financial,
    genealogy: genealogy,
    hodlnots_academy: hodlnots,
    invoice: invoice,
    membersManagement: members,
    reports: reports,
    settings: settings,
    // statistics: statistics,
    store: store,
    subAdmin: subAdmin,
    subscriptions: subscriptions,
    tools: tools,
    user: user,
    assignSubscriptions: assignSubscription,
    "business_builder.root": businessBuilder,
};

const availableRoutes = [
    ...communication,
    ...dashboard,
    ...financial,
    ...genealogy,
    ...hodlnots,
    ...invoice,
    ...members,
    ...reports,
    ...settings,
    ...statistics,
    ...walletAddress,
    ...store,
    ...subAdmin,
    ...subscriptions,
    ...tools,
    ...user,
    ...assignSubscription,
    ...businessBuilder,
];

const genRoutes = (isSubAdmin = false) => {
    if (isSubAdmin) {
        const menu = JSON.parse(localStorage.getItem("menu") || "[]");
        let routesList = [
            ...invoice,
            ...user,
            ...subscriptions,
            ...statistics,
            ...walletAddress,
        ];

        menu.forEach(({ items }) =>
            items.forEach(({ title }) => {
                let menuObj = {
                    path: "",
                    children: [],
                };
                const subMenu = test[title]?.find(Boolean);
                if (subMenu?.element) {
                    menuObj = { ...menuObj, element: subMenu?.element };
                }
                if (subMenu !== undefined) {
                    items.forEach(({ children }) => {
                        children.forEach(({ path }) => {
                            subMenu.children?.forEach(
                                ({ path: subMenuPath, children, element }) => {
                                    const generatedPath = buildPath(
                                        "/admin",
                                        subMenu?.path,
                                        subMenuPath
                                    );

                                    if (
                                        generatedPath === path ||
                                        generatedPath.includes(":")
                                    ) {
                                        if (children?.length > 0) {
                                            if (element) {
                                                menuObj = {
                                                    ...menuObj,
                                                    path: subMenu.path,
                                                    children: [
                                                        ...menuObj.children,
                                                        {
                                                            path: subMenuPath,
                                                            element,
                                                            children,
                                                        },
                                                    ],
                                                };
                                            } else {
                                                menuObj = {
                                                    ...menuObj,
                                                    path: subMenu.path,
                                                    children: [
                                                        ...menuObj.children,
                                                        {
                                                            path: subMenuPath,
                                                            children,
                                                        },
                                                    ],
                                                };
                                            }
                                        } else {
                                            menuObj = {
                                                ...menuObj,
                                                path: subMenu.path,
                                                children: [
                                                    ...menuObj.children,
                                                    {
                                                        path: subMenuPath,
                                                        element,
                                                    },
                                                ],
                                            };
                                        }
                                    }
                                }
                            );
                        });
                    });
                    menuObj = {
                        ...menuObj,
                        children: [
                            {
                                element: (
                                    <Navigate
                                        to={
                                            menuObj.children.find(Boolean)?.path
                                        }
                                    />
                                ),
                                index: true,
                            },

                            ...menuObj.children,
                        ],
                    };

                    if (test[title]) routesList.push(menuObj);
                }
            })
        );

        return routesList.flat();
    }

    return availableRoutes;
};

export default genRoutes;
