import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import Iconify from "src/components/Iconify";
import { IconButtonAnimate } from "src/components/animate";
import Loop from "src/components/loop";
import menu from "../menu";

const MenuItems = () => {
  return (
    <Loop
      list={menu}
      render={({ title, to, icon }) => (
        <Tooltip title={title}>
          <IconButtonAnimate
            LinkComponent={Link}
            to={to}
            sx={{ mr: 1, color: "text.disabled" }}
          >
            <Iconify icon={icon} />
          </IconButtonAnimate>
        </Tooltip>
      )}
    />
  );
};

export default MenuItems;
